/* CustomModal.css */
.custom-modal {
    /* display: none; */
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.7);
    
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    border-radius: 5px;
    border: 1px solid #888;
    width: 35%;
    max-width: 600px;
    position: relative;
  }
  
  .close {
    color: #aaa;
    position: absolute;
    right: 10px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .modal-row {
    display: flex;
    justify-content: space-between; /* Equal space between columns */
    align-items: center; /* Center items vertically */
   
   padding: 12px;
   padding-top: 0px;
  }

  .logo{
    width: 25%;
  }
  .lineHor {
    width: 100%;
    height: 1px;
    background-color: #e1e1e1; /* Horizontal state line */
    margin-bottom: 8px;
  }
  
  .logo img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }

  
  .details {
    flex: 1;
    padding-left: 15px; /* Padding between logo and details */
  }
  .details p{
    font-size: 12px;
    color: #1A2334;
    font-weight: 400;
    margin-bottom: 8px;
  }  
  .amount {
    flex-shrink: 0; /* Prevents the amount column from shrinking */
  }

  
  
  .amount p{
    font-size: 12px;
    color: #1A2334;
    font-weight: 400;
    margin-bottom: 8px;
  }