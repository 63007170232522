:root {
  --theme-color: #244b81;
  --text-color: #000;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Lato', 'Poppins', sans-serif;
}

.bgimg {
  background-image: url(./../assets/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.bgimg2 {
  background-image: url(./../assets/7154.png);
}

.bgcolor {
  background-color: #244b8109;
}

.bgcolor2 {
  background-color: #99999906;
}

a {
  text-decoration: none;
  cursor: pointer;
}

button {
  font-family: Lato;
  font-weight: 300;
  cursor: pointer;
}

.content {
  width: 100%;
  min-height: 300px;
}

.mainContent {
  width: 70%;
  min-width: 960px;
  margin: 0 auto;
  text-align: center;
  padding: 80px 0;
}

.mainContent h2 {
  color: var(--theme-color);
  font-size: 32px;
  margin-bottom: 30px;
}

.mainContent h2::after {
  margin: 20px 0 0 calc(50% - 25px);
  display: block;
  content: '';
  width: 50px;
  text-align: center;
  background-color: var(--theme-color);
  height: 4px;
}

.mainContent p {
  font-size: 17px;
  padding: 40px 0;
  font-weight: 300;
  line-height: 30px;
  color: var(--text-color);
}

.outlineButton {
  padding: 10px 25px;
  background-color: #FFF0;
  border: 1px solid var(--theme-color);
  border-radius: 5px;
  color: var(--theme-color);
}

.solidButton {
  padding: 10px 25px;
  background-color: var(--theme-color);
  border: 1px solid var(--theme-color);
  border-radius: 5px;
  color: #FFF;
}

.outlineWhiteButton {
  padding: 10px 25px;
  background-color: #FFF0;
  border: 1px solid #FFF;
  border-radius: 5px;
  color: #FFF;
}

.solidWhiteButton {
  padding: 10px 25px;
  background-color: #FFF;
  border: 1px solid #FFF;
  border-radius: 5px;
  color: var(--text-color);
}

a.more {
  color: var(--theme-color);
  display: inline-block;
  font-size: 0.8rem;
  padding: 10px;
  margin-top: 20px;
  border-bottom: 2px dashed #CCC;
  border-top: 2px dashed #CCC;
}

a.more i {
  padding-left: 8px;
}

.listContainer {
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  justify-content: space-between;
  margin-left: 12px;
}

.emptyCart,
.myOrderEmptyCart {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--theme-color);
  font-size: 18px;
}

.emptyCart button,
.myOrderEmptyCart button {
  padding: 6px 20px;
  margin: 20px;
  background-color: #fff;
  border: 1px solid var(--theme-color);
  color: var(--theme-color);
  border-radius: 6px;
  font-size: 14px;
  font-weight: 700;
}

.emptyCart h4,
.emptyCart i {
  color: var(--theme-color);
}

.myOrderEmptyCart h4,
.myOrderEmptyCart i {
  color: #bb65f8;
}

/* ----------- Login start --------------- */
.block {
  padding: 15px;
  display: inline-block;
  border: 1px solid #EAEAEC;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 0.5);
  box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 0.5);
  text-align: center;
  width: 40%;
  background-color: #fff;
}

.block h4 {
  color: var(--theme-color);
  padding-bottom: 10px;
}

.inputLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  margin: 10px 0px;
  opacity: 0.7;
  font-weight: 600;
}

.loginInput {
  margin: 10px 0px;
  width: 94%;
  height: 30px;
  border: 1px solid #EAEAEC;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 0.5);
  box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 0.5);
  outline: none;
  padding: 0px 10px;
}

.loginInput:focus {
  border: 1px solid var(--theme-color);
}

.block button {
  color: #fff;
  background-color: var(--theme-color);
  padding: 8px 16px;
  border: 0px;
  font-size: 12px;
  width: 100%;
  margin: 10px 0px;
  font-weight: 600;
  outline: none;
}

.checkbox {
  font-size: 14px;
  opacity: 0.7;
  font-weight: 600;
}

.forgot {
  font-size: 14px;
  opacity: 0.7;
  font-weight: 600;
  color: var(--theme-color);
  text-align: end;
  padding: 10px 0px;
}

.link {
  padding-top: 30px;
}

.link a {
  color: var(--theme-color);
}

.pass-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 14px;
}

.pass-wrapper i {
  position: absolute;
  top: 38%;
  right: 16%;
}

.pass-wrapper i:hover {
  color: #00fcb6;
  cursor: pointer;
}

/* -----------Login End ----------- */
/* -----------aboutus start ----------- */

.about-us-home {
  width: 84%;
  min-width: 960px;
  margin: 0 auto;
  text-align: center;
  padding: 80px 0;
}

.about-us {
  width: 80%;
  min-width: 960px;
  margin: 0 auto;
  text-align: center;
  padding: 80px 0;
  display: flex;
}

.about-us h2,
.policies h2,
.about-us-home h2 {
  color: var(--theme-color);
  font-size: 32px;
}

.about-us h2::after,
.policies h2::after,
.about-us-home h2::after {
  margin: 20px 0 0 calc(50% - 25px);
  display: block;
  content: '';
  width: 50px;
  text-align: center;
  background-color: var(--theme-color);
  height: 4px;
}

.about-us img {
  width: 30%;
  height: 30%;
  background-color: white;
  padding: 10px;
}

.about-us-data {
  padding: 0px 10px;
  width: 68%;
}

.about-us-heading {
  display: none;
}

/* -----------aboutus End ----------- */

.policies {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding: 80px 0;
}

.policies h4,
.policies h5 {
  padding: 10px 10px;
  text-align: center;
  color: var(--theme-color);
}

.list1 {
  list-style: none;
  background-color: #bbb;
  padding: 1%;
  width: 98%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.list1-ul {
  padding: 6px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.list2 {
  padding: 6px 0px;
  width: 90%;
  list-style: square;
}

.list2-ul {
  padding: 6px 0px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.list3 {
  padding: 6px 0px;
  width: 70%;
  list-style: circle;
}

/* -----------Register start ----------- */

.steps {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
}

.stepsImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stepsImage i {
  font-size: 30px;
  border-radius: 30px;
  border: 1px dotted var(--theme-color);
  background-color: var(--theme-color);
  padding: 15px 15px;
}

.stepsImage h6 {
  padding: 10px;
}

.stepsBlock {
  display: inline-block;
  padding: 20px 30px;
  border: 1px solid #EAEAEC;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 0.5);
  box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 0.5);
  text-align: center;
  width: 50%;
  background-color: #fff;
}

.check {
  padding: 10px 4px;
  display: inline-block;
  text-align: right;
}

.check label span {
  margin-left: 4px;
}

.check input {
  cursor: pointer;
  vertical-align: middle;
}

.horizontalLine {
  border: 0.5px solid var(--theme-color);
  margin: 20px 0px;
}

.otpInput {
  padding: 10px 0px;
  border: 0px;
  border-bottom: 2px solid var(--theme-color);
  margin: 0px 10px 20px 10px;
  width: 10%;
  outline: none;
  text-align: center;
}

.stepsBlock button {
  padding: 10px;
  width: 30%;
}

.back {
  background-color: #fff;
  color: var(--theme-color);
  font-size: 12px;
  font-weight: 600;
  outline: none;
  padding: 8px 16px;
  margin: 10px 0px;
}

/* -----------Register End ----------- */


/* -----------ThankYou Start ----------- */
.thankYou {
  height: auto;
  width: 100%;
  display: inline-block;
  text-align: center;
  padding-top: 70px;
}

.thankYou-tick {
  height: 150px;
  width: 100%;
  background-color: #00b55a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thankYou h2 {
  color: #00b55a;
  font-size: 40px;
  margin: 40px 0px 20px;
}

.thankYou-tick i {
  color: #fff;
  font-size: 100px;
}

.thankYou button {
  margin: 20px 0px 40px 0px;
  padding: 10px 20px;
  background-color: #00b55a;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 600;
  border: 0px;
  color: #fff;
  outline: none;
}

.thankYou button i {
  margin-right: 10px;
}

.appLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

/* -----------Thankyou End ----------- */


/*  ------HEADER START------- */
.header {
  width: 100%;
  border-bottom: 1px solid #F1F1F1;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  background: inherit;
  background-color: #fff;
}

.stripContent {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0;
  vertical-align: middle;
}

.headerContent {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
  vertical-align: middle;
}

/*  ------TOP STRIP START------- */
.header .topStrip {
  width: 100%;
  background-color: var(--theme-color);
}

.topStripLeft a {
  color: #FFF;
  font-weight: 400;
  padding: 0 12px;
  font-size: 12px;
}

/* .topStripLeft a:first-child {
  padding-left: 0;
} */
.topStripRight a {
  color: #FFF;
  font-weight: 400;
  border-right: 1px solid #DDD7;
  padding: 0 12px;
  font-size: 12px;
}

.topStripRight .mr4 {
  margin-right: 4px;
}

/* .topStripRight a:last-child {
  border: 0;
  padding-right: 0;
} */

/*  ------HEADER MENU START------- */
.menuBar {
  align-items: center;
  font-size: 24px;
  margin-right: 10px;
  font-weight: 400;
  display: none;
  cursor: pointer;
}

.showMore {
  display: none;
  align-items: center;
  cursor: pointer;
}

.headerMenu {
  background-color: #DDD2;
  -webkit-box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.2);
}

.logoDiv,
.menuDiv,
.loginCartDiv {
  display: flex;
}

.logoImage {
  max-width: 200px;
  max-height: 50px;
  cursor: pointer;
}

.menuDiv a {
  align-self: center;
  color: var(--text-color);
  font-weight: 500;
  padding: 0 12px;
  font-size: 14px;
  border-right: 1px solid #DDD7;
  /* opacity: 0.6; */
}

.dropdown {
  float: left;
  overflow: hidden;
  align-self: center;
  color: var(--text-color);
  font-weight: 500;
  font-size: 14px;
}

.dropdown button {
  align-self: center;
  color: var(--text-color);
  font-weight: 500;
  padding: 0 12px;
  font-size: 14px;
  /* opacity: 0.6; */
  border: 0px;
  outline: none;
  background-color: inherit;
  cursor: pointer;
}

.dropdown button i {
  padding-left: 2px;
}

.more-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  /* min-width: 160px; */
  width: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px 10px 10px 0px;
}

.more-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.more-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .more-content {
  display: block;
}

/* .menuDiv a:last-child {
  border: 0;
} */
.loginCartDiv a {
  align-self: center;
  color: var(--text-color);
  font-weight: 400;
  padding: 0 4px;
}

/* .loginCartDiv a:last-child{
  padding: 0;
} */
.cartno {
  position: absolute;
  padding: 3px 6px;
  margin-left: 20px;
  margin-top: -10px;
  border-radius: 20px;
  background-color: var(--theme-color);
  color: #fff;
  font-size: 10px;
  text-align: center;
}

.cartimg {
  height: 24px;
}

.loginOption {
  align-self: center;
}

.loginOption a,
.login {
  /* border: 1px solid var(--theme-color); */
  background-color: var(--theme-color);
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 12px;
  color: #fff;
  margin-right: 6px;
}

/*  ------HEADER END------- */

/* ------ SLIDER START -----*/

.slider {
  background: url(./../assets/slider.png);
  background-size: cover;
  height: 80vh;
  width: 100%;
}

.slider .slide {
  width: 70%;
  height: 80vh;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.slide .slideTextDiv {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slide .slideTextDiv h2 {
  margin-bottom: 40px;
  font-size: 36px;
  color: #FFF;
  font-weight: 700;
}

.slider .slideTextDiv p {
  /* margin-bottom: 40px; */
  font-size: 18px;
  color: #FFF;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slider div button {
  margin-right: 20px;
  margin-top: 4px;
}

.slide .sliderImgDiv {
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide .sliderImgDiv .sliderBanner {
  max-width: 90%;
}

/* ----- courses div start ------------*/
.coursesContainer {
  width: 100%;
  text-align: center;
  margin: 40px 0 10px 0;
}

.courseDiv {
  width: 92%;
  overflow-y: auto;
  height: 300px;
  margin: 20px 0;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between;  */
  align-items: center;
  border: 1px solid #CCC;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 0.5);
  box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 0.5);
  background-color: #fff;
}

.courseDiv>i {
  font-size: 80px;
  color: var(--theme-color);
  margin: 20px 0;
}

.courseDiv h4 {
  padding: 0;
  font-weight: 700;
  color: var(--theme-color);
  font-size: 1.0rem;
}

.courseDiv p {
  padding: 0;
  text-align: justify;
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0;
  line-height: 22px;
}

.courseDiv button {
  display: inline-block;
  /* margin: 14px 0;   */
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
  padding: 5px 15px;
  border-radius: 4px;
  font-size: 0.8rem;
  text-align: left;
  background-color: #fff;
  font-weight: 500;
  outline: none;
}

.courseDiv a i {
  padding-left: 5px;
}

.longDesc {
  position: absolute;
  top: 0;
  left: 0;
}

.courseDesc {
  position: absolute;
  background-color: white;
  width: 40%;
  height: auto;
  top: 110px;
  left: 30%;
  /* transform: translate(-50%,50%); */
  padding: 10px 10px;
  border-radius: 6px;
  border: 1px solid #CCC;
  padding-left: 30px;
  overflow: auto;
}

.courseDesc h4 {
  border-bottom: 1px solid #EAEAEC;
  padding-bottom: 10px;
  color: var(--text-color);
}

.courseDesc h6 {
  padding-top: 10px;
  color: var(--text-color);
  opacity: 0.5;
}

/* ----- product div start ------------*/
.productContainer {
  width: 100%;
  text-align: center;
  margin: 40px 0 10px 0;
}

.prodDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  height: 360px;
  width: 92%;
  border: 1px solid #CCC;
  margin: 20px 1%;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 0.5);
  box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 0.5);
}

.prodDiv img {
  height: 180px;
  width: 100%;
}

.productDiv {
  overflow-y: auto;
  height: 280px;
  width: 92%;
  margin: 20px 1%;
  display: inline-block;
  border: 1px solid #CCC;
  background-color: #fff3;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 0.5);
  box-shadow: 0px 0px 5px 0px rgba(221, 221, 221, 0.5);
}

.productDiv .productImg {
  width: 100%;
  height: 190px;
  background-size: cover;
  background-position: center;
}

.tag {
  position: absolute;
  color: white;
  background: linear-gradient(#F1A62E, #F4390B);
  margin: 10px 0px 0px -12px;
  font-size: 10px;
  padding: 4px 10px;
}

.productContent {
  padding: 0px 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 170px;
}

.productContent h4 {
  padding: 0;
  font-weight: 700;
  color: var(--theme-color);
  font-size: 1.0rem;
  margin-bottom: 6px;
  text-align: left;
  word-wrap: break-word;
  text-overflow: ellipsis;
}

.productContent h6 {
  font-weight: 700;
  color: var(--text-color);
  font-size: 1rem;
  /* margin-bottom: 10px; */
  text-align: left;
}

.productContent h6 .discount {
  color: var(--text-color);
  font-weight: 300;
  padding-left: 10px;
  text-decoration: line-through;
  opacity: 0.5;
}

.productContent p {
  padding: 0;
  text-align: justify;
  font-size: 0.8rem;
}

.productContent button {
  display: inline-block;
  margin: 6px 0;
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.7rem;
  background-color: white;
  font-weight: 400;
  outline: none;
}

.productContent button i {
  padding-left: 5px;
}

.productDesc {
  width: 90%;
  margin: 0 auto;
  text-align: start;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.productDescLeft {
  width: 40%;
  /* padding: 10px; */
  margin: 0px 10px;
}

.productDescLeft img {
  width: 100%;
  resize: horizontal;
  /* border: 1px solid #EAEAEC; */
  max-height: 150;
}

.product_video_player {
  height: 35vh;
  max-height:350px;
  /* width: 90%; */
  width: 35vw;
  min-width: 200px;
  max-width: 550px;
  resize: "horizontal"
}

.productDescRight {
  width: 60%;
  /* padding: 10px; */
  margin: 0px 10px;
  background-color: #fff;
  border: 1px solid #EAEAEC;
}

.productDescLeftDetail {
  padding: 10px;
  margin-top: 10px;
  background-color: #fff;
  border-bottom: 1px solid #EAEAEC;
}

.productDescLeftDetail h1 {
  font-size: 20px;
  font-weight: 400;
  color: var(--text-color);
  margin-bottom: 10px;
  /* text-align: center; */
}

.productDescLeftDetail h3 {
  font-weight: 700;
  color: var(--text-color);
  font-size: 1rem;
  margin-bottom: 6px;
  /* text-align: center; */
}

.otherTag {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
}

.otherTag div {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}

.otherTag img {
  height: 14px;
  width: 14px;
  margin-right: 4px;
}

.otherTag h5,
h2 {
  font-size: 12px;
}

.productDescLeftDetail h3 .discount {
  color: var(--text-color);
  font-weight: 400;
  padding-left: 10px;
  text-decoration: line-through;
  opacity: 0.5;
  margin: 0px 4px;
}

.productDescLeftDetail button {
  display: inline-block;
  margin: 10px 0;
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
  padding: 5px 15px;
  border-radius: 4px;
  font-size: 0.8rem;
  background-color: white;
  font-weight: 400;
}

.productDescLeftDetail button i {
  padding-left: 5px;
}

.productDetail {
  padding: 0px 0px 20px 0px;
}

.productDetail h1 {
  padding: 10px;
  margin: 0px;
  color: var(--text-color);
  width: 100%;
  background-color: #f1f1f1;
  text-align: start;
  font-size: 14px;
}

.productDetailsList {
  padding: 6px 0px 6px 22px;
}

.firstColumn {
  width: 140px;
  color: var(--text-color);
  opacity: 0.5;
  font-size: 14px;
  display: list-item;
}

.secondColumn {
  font-size: 14px;
  width: auto;
  margin-top: 0px;
  padding-top: 0px;
}

.cart {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cartLeft {
  padding: 10px;
  width: 64%;
  margin: 10px 0px;
}

.cartLeft h4 {
  color: var(--text-color);
  opacity: 0.8;
  font-weight: 500;
}

.offers,
.priceBlock {
  padding: 10px;
  border: 1px solid #EAEAEC;
  border-radius: 4px;
  background-color: #fff;
}

.offers {
  width: 97%;
}

.offersTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.offers h5 {
  font-weight: 400;
}

.offersTop a {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.7;
}

.offerBlock {
  margin: 14px 0px 10px 30px;
}

.offerBlock li {
  font-size: 12px;
  opacity: 0.7;
}

.offerBlock li span {
  color: var(--theme-color);
  opacity: 1;
  font-weight: 800;
  border: 1px solid var(--theme-color);
  padding: 2px 6px;
  margin: 0px 4px;
}

.heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px;
  width: 100%;
}

.cartItem {
  border: 1px solid #EAEAEC;
  padding: 10px;
  margin-bottom: 10px;
  width: 97%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 4px;
  background-color: #fff;
}

.cartItemLeft {
  width: 28%;
  text-align: center;
}

.cartItemLeft img {
  width: 100%;
  height: 110px;
  resize: horizontal;
}

.cartItemRight {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cartItemRight h5 {
  font-size: 16px;
  color: var(--text-color);
  font-weight: 700;
}

.cartItemRight h6 {
  color: #555;
  font-weight: 500;
  font-size: 12px;
  padding: 6px 0px 0px 0px;
}

.cartItemRight h6 span {
  color: var(--theme-color);
  font-weight: 700;
  margin-left: 4px;
}

.cartItemRight a {
  background-color: var(--theme-color);
  color: #fff;
  font-size: 12px;
  width: 30%;
  padding: 6px;
  text-align: center;
  opacity: 0.9;
  font-weight: 800;
}

.cartRight {
  padding: 10px;
  width: 30%;
  margin: 10px 0px;
}

.cartRight h5 {
  font-size: 18px;
  color: var(--text-color);
  padding: 10px;
}

.cartRight h5 span {
  color: var(--theme-color);
  opacity: 0.9;
}

.priceBlock h4 {
  font-size: 12px;
  color: var(--text-color);
  font-weight: 400;
}

.priceBlock h6 {
  font-weight: 400;
  margin-bottom: 10px;
}

.coupon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EAEAEC;
  padding: 10px 0px;
}

.coupon h4 i {
  margin-top: 4px;
  font-size: 14px;
}

.coupon input {
  font-size: 12px;
  padding: 6px 10px;
  width: 66%;
  border: 1px solid #ccc;
  background-color: #fff;
  color: var(--text-color);
  outline: none;
}

.apply {
  border: 1px solid #FF7E40;
  padding: 4px 16px;
  background-color: #fff;
  border-radius: 4px;
  font-size: 12px;
  color: #FF7E40;
  font-weight: 800;
  outline: none;
  margin-left: 6px;
}

.priceDetails {
  padding: 10px 0px;
  border-bottom: 1px solid #EAEAEC;
}

.priceDetails h6 {
  opacity: 0.5;
  padding-bottom: 10px;
}

.price {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.price h4 {
  color: var(--text-color);
}

.payButton {
  width: 100%;
  background-color: #FF7E40;
  color: #fff;
  font-size: 14px;
  text-align: center;
  border: 0px;
  margin: 10px 0px;
  padding: 10px 0px;
  font-weight: 700;
  outline: none;
}

.productDates,
.descProductDates {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.productDates h6,
.descProductDates h6 {
  font-size: 12px;
}

.productDates h5,
.descProductDates h5 {
  font-size: 10px;
  padding-top: 2px;
}

.prodDesc {
  width: 70%;
  overflow-y: auto;
  position: fixed;
  background-color: white;
  height: auto;
  left: 50%;
  transform: translate(-50%, 10%);
  padding: 10px;
  border-radius: 6px;
}

.prodDescHeading {
  display: flex;
  justify-content: space-between;
}

.prodDescHeading h5 {
  width: 90%;
  color: #bb65f8;
}

.prodDescHeading i {
  color: red;
}

.orderDesc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.orderDescLeft {
  width: 34%;
}

.orderDescRight {
  width: 64%
}

/* ----- FAQ div start ------------*/
.faqContainer {
  width: 100%;
  margin: 60px 0 40px 0;
}

.faqContainer .faqs {
  width: 60%;
  text-align: center;
  float: left;
}

.faqContent {
  width: 100%;
  text-align: left;
}

.faqContent h4 {
  width: 100%;
  text-align: left;
  padding: 18px 0;
  color: var(--theme-color);
  font-weight: 400;
  font-size: 1.0rem;
  border-bottom: 1px solid var(--theme-color);
}

.faqContent h4 span {
  float: right;
}

.faqContent h4 span i {
  line-height: 20px;
  cursor: pointer;
}

.faqContent p {
  padding: 5px 0;
  padding-bottom: 15px;
  font-weight: 300;
  line-height: 1.6rem;
  text-align: justify;
  color: var(--text-color);
  opacity: 0.6;
}

.faqContainer .faqImg {
  width: 35%;
  padding-left: 5%;
  padding-top: 5%;
  text-align: center;
  float: left;
}

.faqContainer .faqImg img {
  width: 90%;
}

.faqCategoryItem {
  width: 100%;
  align-self: center;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.faqCategoryItem h3 {
  /* margin: 30px;
  margin-left: 0px; */
  background-color: #f1f1f1;
  width: 98%;
  color: var(--text-color);
  opacity: 0.8;
  font-size: 16px;
  padding: 10px;
  text-align: start;
}

/* ----- testimonial div start ------------*/
.testContainer {
  width: 100%;
  text-align: center;
  margin: 50px 0 10px 0;
}

.testDiv {
  width: 92%;
  overflow: auto;
  height: 300px;
  margin: 60px 1%;
  display: inline-block;
  border: 1px solid #CCC;
  background-color: #fff2;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.5);
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.5);
}

.testDiv .testImg {
  position: fixed;
  width: 130px;
  height: 130px;
  margin-top: -65px;
  border: 5px solid #FFF;
  border-radius: 50%;
  left: calc((92% - 100px)/2);
}

.testContent {
  padding: 25px;
  padding-top: 80px;
}

.testContent .rating {
  padding: 10px 0;
  text-align: center;
}

.testContent .rating i {
  color: gold;
  padding: 0 2px;
}

.testContent h4 {
  padding: 0;
  font-weight: 400;
  color: var(--theme-color);
  font-size: 1.0rem;
  margin-bottom: 15px;
  /* font-style: italic; */
}

.testContent p {
  padding: 0;
  text-align: justify;
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0;
  line-height: 22px;
}

/* ----- footer div start ------------*/
.footer {
  width: 100%;
  text-align: center;
  padding: 40px 0 0px 0;
  background-color: var(--theme-color);
}

.footerContent {
  width: 70%;
  min-width: 960px;
  margin: 0 auto;
}

.footerStrip {
  background-color: #0004;
  padding: 8px 0;
  color: #FFF;
}

.footerSection1 {
  width: 24%;
  text-align: left;
  display: inline-block;
  vertical-align: top;
}

.footerSection2 {
  width: 45%;
  margin-right: 5%;
  text-align: left;
  vertical-align: top;
  display: inline-block;
}

.footerContent h2 {
  color: #FFF;
  font-size: 1rem;
  font-weight: 700;
}

.footerContent h2::after {
  margin: 10px 0 20px 0;
  display: block;
  content: '';
  width: 30px;
  text-align: center;
  background-color: #FFF;
  height: 3px;
}

.footerContent p {
  color: #FFF;
  font-size: 1rem;
  font-weight: 300;
  text-align: justify;
  margin: 16px 0;
}

.footerContent p.social a {
  display: inline-block;
  /* height: 36px;
  width: 36px;
  border-radius: 18px; */
  text-align: center;
  /* border: 1px solid #FFF; */
  margin-right: 10px;
}

.footerContent p.social a i {
  color: #FFF;
  font-size: 1.2rem;
  line-height: 38px;
}

.footerContent img {
  max-width: 150px;
  max-height: 50px;
  margin-bottom: 10px;
}

.footerContent p.contact a {
  display: inline-block;
  margin-right: 20px;
  color: #FFF;
  font-size: 14px;
}

.footerContent p.contact a i {
  padding-right: 5px;
}

.footerSection1 a {
  display: block;
  padding: 5px 0;
  margin: 0px 0;
  color: #FFF;
  font-size: 10px;
}

.footerSection1 a.app,
.appLinks.app {
  margin: 5px 0;
}

.footerSection1 a img,
.appLinks a img {
  width: 100px;
  margin: 0;
}

/*--------------------Alert------------*/

.alert {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.alert-main {
  position: fixed;
  background-color: white;
  width: 30%;
  height: auto;
  left: 50%;
  transform: translate(-50%, 10%);
  padding: 10px;
  border-radius: 6px;
  top: 110px;
  overflow: auto;
}

.alert-main h4 {
  color: var(--text-color);
  border-bottom: 1px solid #EAEAEC;
  padding-bottom: 10px;
}

.alert-main h5{
  color: black;
  padding-bottom: 10px;
  font-weight: normal;
}

.alert-main p {
  padding: 10px 0px;
  font-size: 12px;
}

.buttonContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.cancel {
  padding: 4px 10px;
  background-color: #fff;
  border: 0px;
  color: var(--theme-color);
  font-size: 12px;
  font-weight: 600;
  outline: none;
}

.ok {
  padding: 4px 10px;
  background-color: var(--theme-color);
  border-radius: 4px;
  color: #fff;
  border: 0px;
  font-size: 12px;
  font-weight: 600;
  outline: none;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

/*--------------------Alert End------------*/
/*--------------------Loader Start------------*/

/* Absolute Center Spinner */
.loading,
.dashboardLoader {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before,
.dashboardLoader:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background: -webkit-radial-gradient(var(--theme-color), var(--theme-color));
}

.loading:not(:required):after,
.dashboardLoader:not(:required):after {
  content: '';
  display: block;
  font-size: 14px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  animation: spinner 150ms infinite linear;
  border-radius: 0.6em;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

.dashboardLoader:before {
  background: -webkit-radial-gradient(#bb65f8, #8710db);
}

/*--------------------Loader End------------*/

/*--------------------Dashboard Start ------------*/

.main {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.portalMainContent {
  width: 70%;
  min-width: 960px;
  margin: 0 auto;
  text-align: center;
  padding: 80px 0;
}

.portalMainContent h2 {
  color: #bb65f8;
  font-size: 32px;
  margin-bottom: 30px;
}

.portalMainContent h2::after {
  margin: 20px 0 0 calc(50% - 25px);
  display: block;
  content: '';
  width: 50px;
  text-align: center;
  background-color: #bb65f8;
  height: 4px;
}

header {
  height: 60px;
  position: fixed;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 16px 0 rgba(0, 0, 0, 0.19);
}

.dashboard-header {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-left {
  height: 60px;
  align-items: center;
  display: flex;
  padding-left: 20px;
}

.header-left #show-sidebar {
  padding-right: 10px;
  cursor: pointer;
  display: none;
}

.header-left p {
  font-weight: 700;
  font-size: 12px;
  color: #626061;
  width: 110px;
}

.header-right {
  height: 60px;
  align-items: center;
  display: flex;
  padding-right: 25px;
}

.header-right button {
  border: 1px solid #bb65f8;
  padding: 6px;
  background-color: #fff;
  color: #bb65f8;
  font-weight: 600;
  border-radius: 6px;
  outline: none;
}

.logout {
  margin-left: 20px;
  cursor: pointer;
}

.logout i {
  font-size: 18px;
  font-weight: 400;
  padding-left: 4px;
}

.m20 {
  margin-left: 28px;
}

.head_img {
  width: 26px;
  height: auto;
}

/*scrollbar*/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8710db;
}

.page-wrapper .sidebar-wrapper,
#show-sidebar {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}


/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
  width: 130px;
  height: calc(100% - 60px);
  max-height: 100%;
  position: fixed;
  bottom: 0;
  left: 0px;
  background-color: #fff0;
}

.sidebar-wrapper ul {
  list-style-type: none;
  padding-top: 20px;
  padding-bottom: 60px;
}

.sidebar-wrapper a {
  text-decoration: none;
}

.sidebar-wrapper .icons li img {
  width: 20px;
  height: auto;
  margin-bottom: 2px;
}

.sidebar-wrapper .icons li p {
  margin-block-start: 0.25em;
  line-height: 0.25em;
  font-size: 9px;
  color: #f2ecf8;
  font-weight: 400;
}

/*----------------sidebar-content----------------*/

.sidebar-content {
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #a11eff;
  /*  background-image: linear-gradient(to bottom, #8e62f5 , #2f46f6);*/
}

.sidebar-content>p {
  width: 130px;
  color: #d6b9f3;
  position: fixed;
  bottom: 0em;
  padding: 5px 0;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  background-color: #8710db;
  border-top: 1px solid #bb65f8;
}

.sidebar-content>p .proctur40 {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
}

.icons {
  padding-inline-start: 0px;
}

.icons li {
  padding: 12px 0px;
  text-align: center;
}

.icons li:hover {
  background-color: #8710db;
}

/*--------------------------page-content-----------------------------*/

.page-wrapper .page-content {
  display: inline-block;
  /* width: 100%; */
  width: calc(100% - 131px);
  padding-left: 0px;
  padding-top: 0px;
  margin-left: 130px;
  margin-top: 60px;
  font-family: 'Poppins', sans-serif;
}

.page-wrapper .page-content>div {
  padding: 12px 0px;
}

.page-wrapper .page-content {
  overflow-x: hidden;
}



/*--------------------Dashboard End------------*/

/*--------------------Profile Start------------*/

.profile {
  width: 100%;
}

.profileHeader {
  display: flex;
  padding: 30px 0px;
  border-bottom: 1px solid rgb(204, 204, 206);
}

.profileImg {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileImg i {
  font-size: 100px;
  margin: 10px 0px;
}

.details {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.details button {
  background-color: #9225e0;
  border: 0px;
  padding: 6px 0px;
  width: 200px;
  color: #fff;
  outline: none;
}

.courseDetails {
  padding: 30px;
}

.courseDetails ul {
  padding: 20px 40px;
  display: grid;
  grid-template-columns: 50% 50%;
}

.courseDetails ul li {
  font-size: 12px;
  padding: 10px 0px;
}

/*--------------------Profile End------------*/

/*--------------------Change Pass------------*/
.changePass {
  width: 90%;
  padding-left: 5%;
  margin-top: 3%;
  margin-bottom: 1%;
}

.password {
  display: flex;
  align-items: center;
}

.password i {
  position: absolute;
  right: 40px;
}

/*-------------------Change Pass------------*/

/*--------------------Network Start------------*/
.noNetwork {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*--------------------Network End------------*/

/*--------------------Live Class Start------------*/
.recording {
  position: fixed;
  background-color: white;
  width: 50%;
  height: auto;
  top: 110px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 10px;
  border-radius: 6px;
  border: 1px solid #CCC;
  overflow: auto;
}

/*--------------------Live Class End------------*/
/*--------------------Contact Start------------*/
.contactpage {
  display: flex;
  /* grid-template-columns: 44% 44%; */
  justify-content: space-between;
  width: 100%;
}

.contactpage-left {
  width: 44%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.contact-content,
.social-link {
  display: flex;
  background-color: #fff;
  border: 1px solid #EAEAEC;
  margin: 10px 0px;
  padding: 10px;
  /* border-radius: 6px; */
}

.icon,
.social-link a {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #EAEAEC;
}

.data {
  width: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  padding-left: 6px;
}

.contact-link {
  color: black;
  opacity: 0.7;
  font-size: 12px;
  padding: 10px 0px;
  margin: 0px;
}

.social-link {
  justify-content: space-between;
  align-items: center;
}

.social-link a i {
  color: #000;
  font-size: 1.2rem;
  line-height: 38px;
}

.contactpage-right {
  width: 44%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border: 1px solid #EAEAEC;
  margin: 10px 0px;
  padding: 16px 0px;
}

.contactpage-right input,
.contactpage-right textarea {
  margin-top: 20px;
  height: 34px;
  width: 80%;
  font-family: 'Lato';
  min-width: 80%;
  max-width: 80%;
  min-height: 34px;
}

/*--------------------Contact End------------*/

.bg {
  background-image: url(./../assets/homebg.png);
  background-repeat: no-repeat;
  background-position: top right;
  height: 715px;
  display: flex;
}

.w90 {
  width: 90%;
  margin: 0px auto;
}

.left {
  width: 50%;
}

.date {
  color: #373737;
  font-weight: 300;
  font-size: 30px;
  padding-bottom: 3rem;
}

.weekday {
  color: #9152f8;
  font-weight: 900;
}

.name {
  padding-bottom: 3rem;
}

.name h1 {
  color: #2d73bb;
}

.name h3 {
  font-weight: 300;
  color: #2d73bb;
}

.line {
  width: 90%;
  border-bottom: 4px solid #cbcbcb;
}

.qoute {
  color: #9b999a;
  font-size: 33px;
  font-weight: normal;
  font-style: italic;
  padding: 45px 29px;
}



@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(10deg);
  }

  30% {
    transform: rotate(0deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(0deg);
  }

  60% {
    transform: rotate(5deg);
  }

  70% {
    transform: rotate(0deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

/* -----------------  MEDIA QUERY ------------------------*/


@media only screen and (min-width: 1466px) {}

@media only screen and (max-width: 960px) and (min-width: 640px) {

  .menuDiv {
    display: none;
  }

  .menuBar {
    display: flex;
  }

  .topStripLeft {
    display: none;
  }

  .stripContent {
    justify-content: center;
  }

  .mainContent h2,
  .portalMainContent h2 {
    font-size: 30px;
  }

  .mainContent p {
    font-size: 15px;
    text-align: justify;
  }

  .mainContent,
  .portalMainContent {
    min-width: 96%;
  }

  .faqContainer .faqs {
    width: 94%;
    padding: 3%;
  }

  .faqImg {
    display: none;
  }

  .footerContent {
    min-width: 100%;
  }

  .footerSection1 {
    width: 47.5%;
  }

  .footerSection2 {
    width: 90%;
    margin-bottom: 20px;
  }

  .listContainer {
    grid-template-columns: 33% 33% 33%;
  }

  .courseDetails ul {
    grid-template-columns: 48% 48%;
  }

  .about-us h2,
  .about-us-home h2 {
    font-size: 30px;
  }

  .about-us,
  .about-us-home {
    min-width: 96%;
  }

  .bg {
    display: block;
    background: none;
    height: auto;
  }

  .left {
    width: 100%;
  }

  .line {
    width: 100%;
  }

  .changePasswordBlock {
    width: 50%;
  }

  .forgotBlock {
    width: 50%;
  }

  .block {
    width: 50%;
  }

  .showMore {
    display: flex;
  }

  .loginOption {
    display: none;
  }

  .alert-main {
    width: 40%;
  }

  .courseDesc {
    width: 70%;
    left: 10%;
  }
}

@media only screen and (max-width: 640px) {

  .menuDiv {
    display: none;
  }

  .menuBar {
    display: flex;
  }

  .topStrip {
    display: none;
  }

  .mainContent h2,
  .portalMainContent h2 {
    font-size: 26px;
  }

  .mainContent p {
    font-size: 15px;
    text-align: justify;
  }

  .mainContent,
  .portalMainContent {
    min-width: 96%;
  }

  .faqContainer .faqs {
    width: 94%
  }

  .faqImg {
    display: none;
  }

  .footerContent {
    min-width: 100%;
  }

  .footerSection1 {
    width: 96%;
    margin-bottom: 20px;
  }

  .footerSection2 {
    width: 90%;
    margin-bottom: 20px;
  }

  .listContainer {
    grid-template-columns: 50% 50%;
    justify-content: center
  }

  .cart {
    display: flex;
    flex-direction: column;
  }

  .cartLeft {
    width: 90%;
  }

  .cartRight {
    width: 90%;
  }

  .sideBar {
    display: none;
  }

  .pageContent {
    width: 100%;
    padding: 0px;
  }

  .headerBar {
    width: 100%;
  }

  .courseDetails ul {
    grid-template-columns: 50% 50%;
  }

  .productContent h4 {
    font-size: 0.9rem
  }

  .productContent h6 {
    font-size: 0.6rem
  }

  .productDates div {
    padding-top: 10px;
  }

  .about-us h2,
  .about-us-home h2 {
    font-size: 26px;
  }

  .about-us,
  .about-us-home {
    min-width: 96%;
    flex-direction: column;
  }

  .about-us {
    width: 90%;
  }

  .about-us img {
    width: 80%;
    align-self: center;
  }

  .about-us-heading {
    display: block;
    margin-bottom: 30px;
  }

  .about-us-data {
    width: 90%;
    align-self: center;
    padding-top: 20px;
  }

  .about-us-data h2 {
    display: none;
  }

  .header-left #show-sidebar {
    display: block;
  }

  .sidebar-wrapper {
    left: -150px;
  }

  .toggled .sidebar-wrapper {
    left: 0px;
  }

  .page-wrapper .page-content {
    margin-left: 0;
    width: 100%;
  }

  .bg {
    display: block;
    background: none;
    height: auto;
  }

  .left {
    width: 100%;
  }

  .line {
    width: 100%;
  }

  .changePasswordBlock {
    width: 50%;
  }

  .courseDesc {
    width: 70%;
    left: 10%;
  }

  .forgotBlock {
    width: 50%;
  }

  .policies {
    width: 90%;
    text-align: center;
    padding: 5%;
  }

  .contactpage {
    flex-direction: column;
    align-items: center;
  }

  .contactpage-left,
  .contactpage-right {
    width: 80%;
  }

  .block {
    width: 60%;
  }

  .productDesc {
    flex-direction: column;
  }

  .productDescLeft {
    width: 90%;
    align-self: center;
  }

  .productDescRight {
    width: 80%;
    align-self: center;
    padding: 5%;
  }

  .orderDesc {
    flex-direction: column;
  }

  .orderDescLeft {
    width: 98%;
    align-self: center;
    align-items: center;
  }

  .orderDescRight {
    width: 90%;
    align-self: center;
    align-items: center;
  }

  .orderDescLeft h4 {
    text-align: center;
  }

  .descProductDates {
    margin-bottom: 20px;
    width: 100%;
  }

  .cartimg {
    height: 24px;
  }

  .showMore {
    display: flex;
  }

  .loginOption {
    display: none;
  }

  .productDescLeftDetail {
    margin-bottom: 10px;
  }

  .prodDesc {
    width: 80%;
  }

  .alert-main {
    width: 80%;
  }

  .slider .slide {
    flex-direction: column;
    justify-content: center;
  }

  .slide .sliderImgDiv {
    align-self: center;
  }

  .slide .slideTextDiv {
    width: 100%;
    padding-top: 10px;
  }

  .slide .slideTextDiv h2 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .slider .slideTextDiv p {
    font-size: 13px;
  }

  .cartItem {
    flex-direction: column;
  }

  .cartItemLeft {
    width: 100%;
  }

  .cartItemLeft img {
    height: 150px;
  }

  .cartItemRight {
    width: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .menuBar {
    display: flex;
  }

  .listContainer {
    grid-template-columns: 90%;
    justify-content: center
  }

  .courseDiv h4 {
    font-size: 0.84rem;
  }

  .courseDiv p {
    font-size: 0.7rem
  }

  .productContent h4 {
    font-size: 0.7rem
  }

  .productContent h6 {
    font-size: 0.5rem
  }

  .cart {
    display: flex;
    flex-direction: column;
  }

  .cartLeft {
    width: 90%;
  }

  .cartRight {
    width: 94%;
  }

  .firstColumn {
    width: 70px
  }

  .productDescLeftDetail h1 {
    font-size: 16px;
  }

  .productDescLeftDetail h3 {
    font-size: 12px;
  }

  .productDescLeftDetail button {
    font-size: 10px;
  }

  .otherTag h5,
  h2 {
    font-size: 8px;
  }

  .otherTag img {
    height: 10px;
    width: 10px;
  }

  .secondColumn,
  .firstColumn {
    font-size: 10px;
  }

  .sideBar {
    display: none;
  }

  .pageContent {
    width: 100%;
    padding: 0px;
  }

  .headerBar {
    width: 100%;
  }

  .profileImg i {
    font-size: 50px;
  }

  .courseDetails ul {
    grid-template-columns: 80%;
  }

  .productDates div {
    padding-top: 10px;
  }

  .header-left #show-sidebar {
    display: block;
  }

  .sidebar-wrapper {
    left: -150px;
  }

  .toggled .sidebar-wrapper {
    left: 0px;
  }

  .page-wrapper .page-content {
    margin-left: 0;
    width: 100%;
  }

  .head_img {
    width: 16px;
  }

  .bg {
    display: block;
    background: none;
    height: auto;
  }

  .left {
    width: 100%;
  }

  .line {
    width: 100%;
  }

  .prodDesc {
    width: 90%;
  }

  .productDetail h1 {
    font-size: 14px;
  }

  .changePasswordBlock {
    width: 80%;
  }

  .courseDesc {
    width: 70%;
    left: 10%;
  }

  .block {
    width: 80%;
  }

  .forgotBlock {
    width: 80%;
  }

  .stepsBlock {
    width: 80%;
  }

  .showMore {
    display: flex;
  }

  .showMore button {
    font-size: 10px;
  }

  .menuBar i {
    font-size: 20px;
  }
}